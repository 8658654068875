<template>
     <footer>
          <div class="container">
               <div class="columns ">
                    <div class="column is-3  p-0">
                         <div class="footer-heading">
                              CTL Infocom <br />
                              Private Limited
                         </div>
                    </div>
                    <div class="column is-9  ">
                         <div class="columns">
                              <div class="column  is-4 p-0">
                                   <div class="location  ">
                                        MUMBAI

                                        <div style="font-weight:300">
                                             B-621 Jaswanti Business Centre,<br />
                                             Ramchandra Ln, Kanchpada,<br />
                                             Malad West, Mumbai,<br />
                                             Maharashtra - 400064
                                        </div>
                                   </div>
                              </div>

                              <div class="column   p-0 ">
                                   <div class="location ">
                                        BENGALURU
                                        <div style="font-weight:300">
                                             3, Maruthi Corner,<br />
                                             Banaswadi Main Road,<br />
                                             Bengaluru,<br />
                                             Karnataka - 560043
                                        </div>
                                   </div>
                              </div>
                              <div class="column    p-0">
                                   <div class="location ">
                                        KOCHI
                                        <div style="font-weight:300">
                                             5C, K G Oxford Centre,<br />
                                             Sreekandath Road,<br />
                                             Ravipuram, Ernakulam,<br />
                                             Kerala - 682016
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div class="columns mt-20 p-0">
                    <div class="column  is-4 p-0  stayintouch">
                         <div style="margin-top:auto" class="">
                              <div class="emailinputcontainer">
                                   <span class="label"
                                        >Let's Stay in Touch

                                        <div class="socials">
                                             <a href="">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="6.061" height="12.12" viewBox="0 0 6.061 12.12">
                                                       <path
                                                            id="facebook"
                                                            d="M11.141,2.012h1.107V.085A14.288,14.288,0,0,0,10.636,0,2.559,2.559,0,0,0,7.948,2.848v1.7H6.187V6.7H7.948V12.12h2.158V6.7H11.8l.268-2.154H10.105V3.061C10.106,2.439,10.274,2.012,11.141,2.012Z"
                                                            transform="translate(-6.187)"
                                                            fill="#fff"
                                                       />
                                                  </svg>
                                             </a>

                                             <a href="">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="11.927" height="11.927" viewBox="0 0 11.927 11.927">
                                                       <g id="Group_456" data-name="Group 456" transform="translate(0)">
                                                            <path
                                                                 id="Path_1891"
                                                                 data-name="Path 1891"
                                                                 d="M16.195,15.737h0V11.362c0-2.14-.461-3.788-2.963-3.788A2.6,2.6,0,0,0,10.9,8.86h-.035V7.774H8.489v7.963h2.47V11.794c0-1.038.2-2.042,1.483-2.042,1.267,0,1.286,1.185,1.286,2.109v3.877Z"
                                                                 transform="translate(-4.271 -3.81)"
                                                                 fill="#fff"
                                                            />
                                                            <path
                                                                 id="Path_1892"
                                                                 data-name="Path 1892"
                                                                 d="M.4,7.977H2.869V15.94H.4Z"
                                                                 transform="translate(-0.208 -4.013)"
                                                                 fill="#fff"
                                                            />
                                                            <path
                                                                 id="Path_1893"
                                                                 data-name="Path 1893"
                                                                 d="M1.432,0A1.439,1.439,0,1,0,2.865,1.432,1.433,1.433,0,0,0,1.432,0Z"
                                                                 transform="translate(0 0)"
                                                                 fill="#fff"
                                                            />
                                                       </g>
                                                  </svg>
                                             </a>
                                        </div>
                                   </span>

                                   <div class="emailinput  ">
                                        <span class="email">hello@ctl.in</span>
                                        <span class="arrow" @click="redirectToContact">
                                             <svg xmlns="http://www.w3.org/2000/svg" width="42.761" height="19.504" viewBox="0 0 42.761 19.504">
                                                  <g id="Group_57" data-name="Group 57" transform="translate(0.232 0.354)">
                                                       <line
                                                            id="Line_40"
                                                            data-name="Line 40"
                                                            x2="42"
                                                            transform="translate(-0.232 9.347)"
                                                            fill="none"
                                                            stroke-width="1"
                                                       />
                                                       <path
                                                            id="Path_21"
                                                            data-name="Path 21"
                                                            d="M1103.635,126.653l9.4,9.4-9.4,9.4"
                                                            transform="translate(-1071.211 -126.653)"
                                                            fill="none"
                                                            stroke-width="1"
                                                       />
                                                  </g>
                                             </svg>
                                        </span>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div class="column is-5 p-0 ">
                         <div style="margin-top:40px;">
                              <div class="links ">
                                   <router-link :to="{ name: 'PROJECTS' }" class="tertiary-color">Projects</router-link>
                                   <router-link :to="{ name: 'INDUSTRIES' }" class="tertiary-color">Industries</router-link>
                                   <router-link :to="{ name: 'CERTIFICATION' }" class="tertiary-color">Certifications</router-link>
                                   <router-link :to="{ name: 'ABOUT' }" class="tertiary-color">About Us</router-link>

                                   <router-link :to="{ name: 'SERVICES' }" class="tertiary-color">Solutions</router-link>
                                   <router-link :to="{ name: 'CONTACT' }" class="tertiary-color">Contact</router-link>
                                   <router-link :to="{ name: 'CAREER' }" class="tertiary-color">Careers</router-link>
                                   <a href="https://ctl.asista.com/#/home" target="_blank" class="tertiary-color">Support</a>
                                   <router-link :to="{ name: 'COMINGSOON' }" class="tertiary-color">Blog</router-link>
                              </div>
                         </div>
                    </div>
                    <div class="column p-0 ">
                         <div style="margin-top:40px;" class="">
                              <div class="copyright">
                                   <div>Copyright © 2021 ctl.in</div>
                                   <div class="mt-10">All rights reserved.</div>
                              </div>
                         </div>
                    </div>
                    <!-- <div class="column"></div> -->
               </div>

               <div></div>

               <!-- <div class="columns ">
                    <div class="column ">s</div>
                    <div class="column "></div>
               </div> -->
          </div>
     </footer>
</template>

<script>
     export default {
          data() {
               return {};
          },

          methods: {
               redirectToContact() {
                    this.$router.push({
                         name: "CONTACT",
                    });
               },
          },
     };
</script>

<style lang="scss" scoped>
     @import "@/assets/styles/variables.scss";
     footer {
          width: 100vw;
          overflow-x: hidden !important;
          animation-name: fadein;
          animation-duration: 2s;
          padding: 50px 0 40px;
          color: $tertiary;
          background-color: $footer_bg;

          @media (max-width: 1023px) {
               padding: 50px 10px 40px;
          }
          .column {
               padding-right: 40px;
          }
          .copyright {
               font-weight: 300;
               display: block;
               margin-bottom: 0;

               @media (max-width: 700px) {
                    text-align: center;
                    div {
                         display: inline !important;

                         margin-right: 10px;
                    }
               }
          }

          .location {
               padding: 0 10px;
               @media (max-width: 769px) {
                    margin-top: 15px;
                    padding: 0;
               }
          }
          .footer-heading {
               font-size: 32px;
               font-weight: 600;
          }
          .label {
               font-size: 19px;
               font-weight: 300;
               color: white;
               padding-bottom: 5px;
          }

          .stayintouch {
               @media (max-width: 1023px) {
                    margin-top: 35px;
               }
          }

          .emailinputcontainer {
               max-width: 350px;

               .socials {
                    float: right;
                    display: inline-block;

                    a {
                         margin-left: 7px;

                         display: inline-block;
                         width: 25px;

                         svg {
                              transform: scale(1.22);
                              transition: transform 0.3s;
                         }
                    }
                    a:hover {
                         svg {
                              transform: scale(1.32);
                         }
                    }
               }
               .emailinput {
                    position: relative;

                    .email {
                         background-color: transparent;
                         border: 1px solid white;
                         color: white;
                         padding: 18px 20px;
                         padding-right: 80px;
                         display: inline-block;
                         width: 100%;
                         border-radius: 0;
                         outline: none;
                         font-size: 16px;
                         font-family: "Poppins";
                         color: white;
                    }
                    .email::placeholder {
                         color: rgba(255, 255, 255, 0.8);
                    }
                    .arrow {
                         svg {
                              transition: stroke 0.3s;
                              stroke: #89c184;
                         }

                         position: absolute;
                         top: 22px;
                         right: 20px;
                    }

                    .arrow:hover {
                         cursor: pointer;
                         svg {
                              stroke: white;
                         }
                    }
               }
          }
          a {
               font-weight: 300;
          }
          .links {
               padding: 0 30px;
               @media (max-width: 769px) {
                    margin-top: 35px;
                    padding: 0;
               }
               a {
                    margin-right: 30px;
                    margin-bottom: 10px;
                    display: inline-block;
               }
          }
     }
</style>
