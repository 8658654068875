<template>
     <div class="navcontainer">
          <fixed-header :threshold="threshold" class="navbar">
               <div>
                    <nav class="container">
                         <div class="columns is-mobile" style="margin:0;padding:0;width:100%;">
                              <router-link class="navitem" to="/"> <img class="logo " src="@/assets/images/logo.png" alt=""/></router-link>
                              <div class="column  p-0">
                                   <div class="navlinks " v-if="$route.name == 'HOME'">
                                        <div class=" is-hidden-touch  ">
                                             <div class="hide-on-fixed">
                                                  <a href="tel:+919895016067" target="_blank">+91 9895016067</a>
                                                  <router-link class="navitem " :to="{ name: 'CONTACT' }">hello@ctl.in</router-link>
                                                  <a href="https://ctl.asista.com/#/home" target="_blank">Support</a>
                                             </div>
                                             <div class="show-on-fixed">
                                                  <router-link class="navitem" :to="{ name: 'ABOUT' }">About</router-link>
                                                  <router-link class="navitem" :to="{ name: 'INDUSTRIES' }">Industries</router-link>
                                                  <router-link class="navitem" :to="{ name: 'PROJECTS' }">Projects</router-link>
                                                  <router-link class="navitem" :to="{ name: 'SERVICES' }">Solutions</router-link>
                                                  <router-link class="navitem" :to="{ name: 'CONTACT' }">Contact</router-link>
                                                  <a href="https://ctl.asista.com/#/home" target="_blank">Support</a>
                                             </div>
                                        </div>
                                        <div class="is-hidden-desktop ">
                                             <button class="menubtn" @click="isMenuOpen = true">
                                                  <svg
                                                       id="Group_11"
                                                       data-name="Group 11"
                                                       xmlns="http://www.w3.org/2000/svg"
                                                       width="30.019"
                                                       height="8.435"
                                                       viewBox="0 0 30.019 8.435"
                                                  >
                                                       <rect
                                                            id="Rectangle_5"
                                                            data-name="Rectangle 5"
                                                            width="30.019"
                                                            height="2"
                                                            transform="translate(30.019 2) rotate(180)"
                                                            fill="#44aa52"
                                                       />
                                                       <rect
                                                            id="Rectangle_6"
                                                            data-name="Rectangle 6"
                                                            width="30.019"
                                                            height="2"
                                                            transform="translate(30.019 8.435) rotate(180)"
                                                            fill="#44aa52"
                                                       />
                                                  </svg>

                                                  menu
                                             </button>
                                        </div>
                                   </div>

                                   <div class="navlinks " v-else>
                                        <div class=" is-hidden-touch  ">
                                             <router-link class="navitem" :to="{ name: 'ABOUT' }">About</router-link>
                                             <router-link class="navitem" :to="{ name: 'INDUSTRIES' }">Industries</router-link>
                                             <router-link class="navitem" :to="{ name: 'PROJECTS' }">Projects</router-link>
                                             <router-link class="navitem" :to="{ name: 'SERVICES' }">Solutions</router-link>
                                             <router-link class="navitem" :to="{ name: 'CONTACT' }">Contact</router-link>
                                             <a href="https://ctl.asista.com/#/home" target="_blank">Support</a>
                                        </div>

                                        <div class="is-hidden-desktop ">
                                             <button class="menubtn" @click="isMenuOpen = true">
                                                  <svg
                                                       id="Group_11"
                                                       data-name="Group 11"
                                                       xmlns="http://www.w3.org/2000/svg"
                                                       width="30.019"
                                                       height="8.435"
                                                       viewBox="0 0 30.019 8.435"
                                                  >
                                                       <rect
                                                            id="Rectangle_5"
                                                            data-name="Rectangle 5"
                                                            width="30.019"
                                                            height="2"
                                                            transform="translate(30.019 2) rotate(180)"
                                                            fill="#44aa52"
                                                       />
                                                       <rect
                                                            id="Rectangle_6"
                                                            data-name="Rectangle 6"
                                                            width="30.019"
                                                            height="2"
                                                            transform="translate(30.019 8.435) rotate(180)"
                                                            fill="#44aa52"
                                                       />
                                                  </svg>

                                                  menu
                                             </button>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </nav>
               </div>
          </fixed-header>

          <b-sidebar
               :fullheight="true"
               :fullwidth="false"
               :overlay="false"
               :right="true"
               v-model="isMenuOpen"
               @close="isMenuOpen = false"
               style="z-index:10000"
               mobile="fullwidth"
          >
               <div>
                    <div class="closebtn">
                         <button @click="isMenuOpen = false">
                              <svg xmlns="http://www.w3.org/2000/svg" width="30.423" height="30.423" viewBox="0 0 30.423 30.423">
                                   <g id="sy" transform="translate(-4 8.641)">
                                        <rect id="b" width="38.722" height="4.302" transform="translate(34.423 -5.599) rotate(135)" />
                                        <rect id="a" width="38.722" height="4.302" transform="translate(31.38 21.782) rotate(-135)" />
                                   </g>
                              </svg>
                         </button>
                         <span> menu</span>
                    </div>

                    <div class="links">
                         <router-link class="link " :to="{ name: 'ABOUT' }" @click="isMenuOpen = false">About</router-link>
                         <router-link class="link" :to="{ name: 'INDUSTRIES' }">Industries</router-link>
                         <router-link class="link" :to="{ name: 'PROJECTS' }">Projects</router-link>
                         <router-link class="link" :to="{ name: 'SERVICES' }">Solutions</router-link>
                         <router-link class="link" :to="{ name: 'CONTACT' }">Contact</router-link>
                         <a href="https://ctl.asista.com/#/home" class="link " target="_blank">Support</a>
                    </div>
               </div>
          </b-sidebar>
     </div>
</template>

<script>
     import FixedHeader from "vue-fixed-header";
     export default {
          data() {
               return {
                    fixed: false,
                    isMenuOpen: false,
               };
          },
          components: {
               FixedHeader,
          },

          props: {
               // fixed: {
               //      type: Boolean,
               //      default: false,
               // },
          },
          watch: {
               $route(to, from) {
                    this.isMenuOpen = false;
               },
          },
          mounted() {
               // bodyScrollLock.disableBodyScroll(targetElement);
          },
          computed: {
               threshold() {
                    if (window.innerWidth > 700) {
                         return 600;
                    }
                    return 100;
               },
          },
     };
</script>

<style lang="scss" scoped>
     @import "@/assets/styles/variables.scss";

     @keyframes fadein {
          from {
               opacity: 0;
          }
          to {
               opacity: 1;
          }
     }

     .hide-on-fixed {
          display: block;
     }
     .show-on-fixed {
          display: none;
     }

     .navbar.vue-fixed-header--isFixed {
          .hide-on-fixed {
               display: none;
          }
          .show-on-fixed {
               display: block;
          }
          animation-name: fadein;
          animation-duration: 0.5s;

          position: fixed !important;

          top: 0 !important;
          width: 100% !important;
          margin: auto;

          z-index: 200 !important;
          img.logo {
               height: 55px;
               padding-top: 10px;
          }
          nav {
               padding: 20px 0;
          }
     }
     .navbar {
          // transform: translate3d(0, 0, 0);

          background-color: $primary_bg;
          opacity: 1;

          img.logo {
               @media screen and (max-width: 1023px) {
                    height: 45px;
               }
          }
     }

     .navcontainer {
          min-height: 146px;
          @media (max-width: 1023px) {
               min-height: 80px;
          }
          width: 100%;
     }
     nav {
          padding: 40px 0;

          .navlinks {
               display: flex;
               justify-content: flex-end;
               align-items: center;
               height: 100%;

               text-align: right;

               a {
                    display: inline-block;
                    margin-left: 40px;
                    font-weight: 600;
                    font-size: 17px;
                    text-transform: lowercase;
               }
          }
          .menubtn {
               cursor: pointer;
               border: none;
               background: transparent;
               outline: none;
               font-weight: 400;
               color: $secondary;
               font-size: 20px;
               svg {
                    margin-right: 5px;
               }
               margin-right: 5px;
          }
     }
</style>
